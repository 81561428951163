import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5ae768cf"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "recharge"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  class: "mb-8"
};
const _hoisted_4 = {
  class: "mb-8"
};
const _hoisted_5 = {
  class: "mb-8"
};
const _hoisted_6 = {
  class: "withdrawal-item"
};
const _hoisted_7 = {
  class: "withdrawal-item"
};
import NP from 'number-precision';
import { router } from '@/router';
import { computed, ref } from 'vue';
import { currencysList, withdraw } from '@/http';
import { showToast } from 'vant';
export default {
  __name: 'withdrawal',
  setup(__props) {
    const amount = ref('');
    const payPassword = ref('');
    const fee = computed(() => {
      if (withdrawalData.value.length && amount.value) {
        return NP.times(amount.value, withdrawalData.value[index.value].fee / 100);
      }
      return 0;
    });
    const show = ref(false);
    const address = ref('');
    const back = () => {
      router.go(-1);
    };
    const actions = ref([]);
    const withdrawalData = ref([]);
    const index = ref(0);
    const typeSelect = item => {
      index.value = item.value;
      show.value = false;
    };
    const _currencysList = () => {
      currencysList({
        type: 'withdraw'
      }).then(({
        data
      }) => {
        withdrawalData.value = data;
        data.map((item, index) => {
          actions.value.push({
            name: `${item.name}-${item.agreement.toUpperCase()}`,
            value: index
          });
        });
      });
    };
    _currencysList();
    const _withdraw = () => {
      withdraw({
        currency_id: withdrawalData.value[index.value].id,
        amount: amount.value,
        addr: address.value,
        pay_password: payPassword.value
      }).then(({
        code,
        msg
      }) => {
        showToast(msg);
        if (code === 200) {
          router.back();
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        "left-arrow": "",
        title: _ctx.$t('withdraw'),
        placeholder: "",
        fixed: "",
        onClickLeft: back
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        class: "recharge-form__item",
        onClick: _cache[0] || (_cache[0] = $event => show.value = true)
      }, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('please_select_withdrawal_currency')), 1), _createElementVNode("div", null, [withdrawalData.value.length ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(withdrawalData.value[index.value].name) + "-" + _toDisplayString(withdrawalData.value[index.value].agreement), 1)) : _createCommentVNode("", true), _createVNode(_component_van_icon, {
        name: "arrow"
      })])]), _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('withdrawal_amount')), 1), _createVNode(_component_van_field, {
        placeholder: _ctx.$t('please_enter_withdrawal_amount'),
        class: "br-a__8 mb-18",
        modelValue: amount.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => amount.value = $event),
        type: "number"
      }, null, 8, ["placeholder", "modelValue"]), _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('withdrawal_address')), 1), _createVNode(_component_van_field, {
        placeholder: _ctx.$t('please_enter_withdrawal_address'),
        class: "br-a__8 mb-18",
        modelValue: address.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => address.value = $event)
      }, null, 8, ["placeholder", "modelValue"]), _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('transaction_password')), 1), _createVNode(_component_van_field, {
        type: "password",
        placeholder: _ctx.$t('please_enter_transaction_password'),
        class: "br-a__8 mb-18",
        modelValue: payPassword.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => payPassword.value = $event)
      }, null, 8, ["placeholder", "modelValue"]), _createElementVNode("div", _hoisted_6, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('handling_fee')), 1), _createElementVNode("span", null, _toDisplayString(fee.value), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('estimated_actual_arrival')), 1), _createElementVNode("span", null, _toDisplayString(_unref(NP).minus(amount.value, fee.value)), 1)]), _createVNode(_component_van_button, {
        block: "",
        round: "",
        color: "#FC72FF",
        onClick: _withdraw
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('submit')), 1)]),
        _: 1
      })]), _createVNode(_component_van_action_sheet, {
        show: show.value,
        "onUpdate:show": _cache[4] || (_cache[4] = $event => show.value = $event),
        onSelect: typeSelect,
        actions: actions.value,
        "cancel-text": _ctx.$t('cancel'),
        "close-on-click-action": "",
        onCancel: _cache[5] || (_cache[5] = $event => show.value = false)
      }, null, 8, ["show", "actions", "cancel-text"])], 64);
    };
  }
};